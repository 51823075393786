import { call, put, select } from 'redux-saga/effects';
import { get } from 'lodash';
import axios from 'axios';

import { MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS, showModal } from './modal';
import * as api from '../../api';
import history from '../../history';
import { configAndResolvedWithNewFields, removeProperty } from '../../utils';

// Actions
export const FETCH_CONFIG_REQUEST = 'config-editor/config/FETCH_CONFIG_REQUEST';
export const FETCH_CONFIG_SUCCESS = 'config-editor/config/FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_FAIL = 'config-editor/config/FETCH_CONFIG_FAIL';

export const UPDATE_CONFIG_REQUEST = 'config-editor/config/UPDATE_CONFIG_REQUEST';
export const UPDATE_CONFIG_SUCCESS = 'config-editor/config/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAIL = 'config-editor/config/UPDATE_CONFIG_FAIL';
export const UPDATE_CONFIG_LIVE = 'config-editor/config/UPDATE_CONFIG_LIVE';

export const VALIDATE_CONFIG_REQUEST = 'config-editor/config/VALIDATE_CONFIG_REQUEST';
export const VALIDATE_CONFIG_SUCCESS = 'config-editor/config/VALIDATE_CONFIG_SUCCESS';
export const VALIDATE_CONFIG_FAIL = 'config-editor/config/VALIDATE_CONFIG_FAIL';

export const FETCH_IMPORT_CONFIG_REQUEST = 'config-editor/config/FETCH_IMPORT_CONFIG_REQUEST';
export const FETCH_IMPORT_CONFIG_SUCCESS = 'config-editor/config/FETCH_IMPORT_CONFIG_SUCCESS';
export const FETCH_IMPORT_CONFIG_FAIL = 'config-editor/config/FETCH_IMPORT_CONFIG_FAIL';

export const IMPORT_CONFIG_VALID = 'config-editor/config/IMPORT_CONFIG_VALID';
export const IMPORT_CONFIG_INVALID = 'config-editor/config/IMPORT_CONFIG_INVALID';
export const IMPORT_CONFIG_TEMP = 'config-editor/config/IMPORT_CONFIG_TEMP';
export const CLEAR_CONFIG_TEMP = 'config-editor/config/CLEAR_CONFIG_TEMP';

export const RESET_CONFIG = 'config-editor/config/RESET_CONFIG';

export const UPDATE_REQUEST_STATUS_CODE = 'config-editor/config/UPDATE_REQUEST_STATUS_CODE';

export const GET_JWT_FAIL = 'config-editor/config/GET_JWT_FAIL';

export const UPDATE_CONFIG_LIVE_EXCLUDES = 'config-editor/config/UPDATE_CONFIG_LIVE_EXCLUDES';

export const DISCARD_ACTIVE_TAB_CHANGES = 'config-editor/config/DISCARD_ACTIVE_TAB_CHANGES';

// Default State
const defaultState = {
  loading: false,
  globalLoading: false,
  resolvedConfig: {
    version: 1,
    entities: [],
  },
  config: {
    version: 1,
    entities: [],
    excludes: [],
  },
  serverConfig: {
    version: 1,
    entities: [],
  },
  serverResolvedConfig: {
    version: 1,
    entities: [],
  },
  tempData: null,
  error: null,
  requestStatusCode: 0,
  isSuccessJWTRequest: true,
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case FETCH_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CONFIG_SUCCESS: {
      const config = action.payload.config;
      const resolvedConfig = action.payload.resolvedConfig;
      const { newConfig, newResolvedConfig } = configAndResolvedWithNewFields(config, resolvedConfig);

      return {
        ...state,
        loading: false,
        config: newConfig,
        resolvedConfig: newResolvedConfig,
        serverConfig: newConfig,
        serverResolvedConfig: newResolvedConfig,
        tempData: null,
        error: null,
      };
    }

    case UPDATE_REQUEST_STATUS_CODE:
      return {
        ...state,
        requestStatusCode: action.requestStatusCode,
      };

    case FETCH_CONFIG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case UPDATE_CONFIG_REQUEST:
      return {
        ...state,
        globalLoading: true,
        error: null,
      };

    case UPDATE_CONFIG_SUCCESS: {
      const config = action.payload.config;
      const resolvedConfig = action.payload.resolvedConfig;
      const { newConfig, newResolvedConfig } = configAndResolvedWithNewFields(config, resolvedConfig);

      return {
        ...state,
        globalLoading: false,
        config: newConfig,
        resolvedConfig: newResolvedConfig,
        serverConfig: newConfig,
        serverResolvedConfig: newResolvedConfig,
        tempData: state.tempData,
        error: null,
      };
    }

    case UPDATE_CONFIG_FAIL:
      return {
        ...state,
        globalLoading: false,
        error: action.error,
      };

    case UPDATE_CONFIG_LIVE: {
      const { field, config = [], resolvedConfig = [] } = action.payload;

      return {
        ...state,
        config: {
          ...state.config,
          ...{ [field]: config },
        },
        resolvedConfig: {
          ...state.resolvedConfig,
          ...{ [field]: resolvedConfig },
        },
      };
    }

    case IMPORT_CONFIG_VALID: {
      const { excludes, ...configurationWithoutExcludes } = action.payload;

      return {
        ...state,
        config: {
          ...configurationWithoutExcludes,
        },
        resolvedConfig: {
          ...configurationWithoutExcludes,
        },
        tempData: null,
        error: null,
      };
    }

    case IMPORT_CONFIG_INVALID:
      return {
        ...state,
        error: action.error,
      };

    case IMPORT_CONFIG_TEMP:
      return {
        ...state,
        tempData: action.tempConfig,
      };

    case CLEAR_CONFIG_TEMP:
      return {
        ...state,
        tempData: null,
      };

    case FETCH_IMPORT_CONFIG_REQUEST:
      return {
        ...state,
        globalLoading: true,
        error: null,
      };

    case FETCH_IMPORT_CONFIG_SUCCESS: {
      const config = action.payload.config;
      const resolvedConfig = action.payload.resolvedConfig;
      const { newConfig, newResolvedConfig } = configAndResolvedWithNewFields(config, resolvedConfig);

      return {
        ...state,
        globalLoading: false,
        config: newConfig,
        resolvedConfig: newResolvedConfig,
        serverConfig: newConfig,
        serverResolvedConfig: newResolvedConfig,
        tempData: null,
        error: null,
      };
    }

    case FETCH_IMPORT_CONFIG_FAIL:
      return {
        ...state,
        loading: false,
        globalLoading: false,
        error: action.error,
      };

    case VALIDATE_CONFIG_REQUEST:
      return {
        ...state,
        globalLoading: true,
        error: null,
      };

    case VALIDATE_CONFIG_SUCCESS:
      return {
        ...state,
        globalLoading: false,
      };

    case VALIDATE_CONFIG_FAIL:
      return {
        ...state,
        globalLoading: false,
        error: action.error,
      };

    case GET_JWT_FAIL:
      return {
        ...state,
        status: action.status,
        isSuccessJWTRequest: false,
      };

    case UPDATE_CONFIG_LIVE_EXCLUDES: {
      const configExcludes = state.config.excludes || [];
      const { entities = [], types = [] } = state.config;
      const { entities: serverResolvedConfigEntities, types: serverResolvedConfigTypes } = state.serverResolvedConfig;
      const elementIndex = configExcludes.findIndex(({ configuration }) => configuration === action.payload.configuration);
      const newElementIndex = configExcludes ? elementIndex : 0;
      const newConfigExcludes = elementIndex !== -1 ? [
        ...configExcludes.slice(0, newElementIndex),
        {
          ...configExcludes[newElementIndex],
          excludes: !configExcludes[newElementIndex].excludes,
        },
        ...configExcludes.slice(newElementIndex + 1),
      ] : [...configExcludes, action.payload];

      const filterNewConfigExcludes = newConfigExcludes.filter(({ excludes }) => excludes);
      const configExcludesArray = filterNewConfigExcludes.map(({ configuration }) => configuration);
      const newResolvedConfigEntities = serverResolvedConfigEntities.filter(item => {
        const { name, isExtends } = item;
        if (isExtends) {
          const entityName = name.split(':')[0];
          return !configExcludesArray.includes(entityName);
        }

        return false;
      });

      const serverTypesConcatWithLocal = serverResolvedConfigTypes.concat(types);
      const onlyUniqueTypes = serverTypesConcatWithLocal.filter((obj, key, array) => array.map((obj2) => obj.name !== obj2.name));
      const newResolvedConfigTypes = onlyUniqueTypes.filter(item => {
        const { name, isExtends } = item;
        if (isExtends) {
          const typeName = name.split(':')[0];
          return !configExcludesArray.includes(typeName);
        }

        return true;
      });

      return {
        ...state,
        config: {
          ...state.config,
          excludes: filterNewConfigExcludes,
          types: newResolvedConfigTypes,
        },
        resolvedConfig: {
          ...state.resolvedConfig,
          entities: [...entities, ...newResolvedConfigEntities],
          types: newResolvedConfigTypes,
        },
      };
    }

    case DISCARD_ACTIVE_TAB_CHANGES: {
      const {
        config: { entities: configEntities, excludes: configExcludes, types: configTypes, extends: configExtends },
        resolvedConfig: { entities: resolvedEntities, types: resolvedTypes },
      } = state;
      const {
        activeTab,
        activeTabState: {
          config: { entities: activeTabConfigEntities, excludes: activeTabConfigExcludes, types: activeTabConfigTypes, extends: activeTabConfigExtends },
          resolvedConfig: { entities: activeTabResolvedEntities, types: activeTabResolvedTypes },
        },
      } = action.payload;
      const resolvedConfigActiveTabState = activeTabResolvedEntities.find(({ name }) => name === activeTab);
      const resolvedEntityIndex = resolvedEntities.findIndex(({ name }) => name === activeTab);
      const configActiveTabState = activeTabConfigEntities.find(({ name }) => name === activeTab);
      const configEntityIndex = configEntities.findIndex(({ name }) => name === activeTab);
      let newResolvedEntities = resolvedEntities;
      let newConfigEntities = configEntities;
      let newExcludes = configExcludes;
      let newConfigTypes = configTypes;
      let newResolvedTypes = resolvedTypes;
      let newConfigExtends = configExtends;

      if (activeTab === 'entities') {
        newResolvedEntities = activeTabResolvedEntities;
        newConfigEntities = activeTabConfigEntities;
      } else if (activeTab === 'namespaces') {
        newExcludes = activeTabConfigExcludes;
        newResolvedEntities = activeTabResolvedEntities;
        newConfigEntities = activeTabConfigEntities;
      } else if (activeTab === 'types') {
        newConfigTypes = activeTabConfigTypes;
        newResolvedTypes = activeTabResolvedTypes;
      } else if (activeTab === 'summary') {
        newConfigExtends = activeTabConfigExtends;
      } else {
        newResolvedEntities = [
          ...resolvedEntities.slice(0, resolvedEntityIndex),
          {
            ...resolvedConfigActiveTabState,
          },
          ...resolvedEntities.slice(resolvedEntityIndex + 1),
        ];
        newConfigEntities = configEntityIndex !== -1 && configActiveTabState ? [
          ...configEntities.slice(0, configEntityIndex),
          {
            ...configActiveTabState,
          },
          ...configEntities.slice(configEntityIndex + 1),
        ] : configEntities.filter(({ name }) => name !== activeTab);
      }

      return {
        ...state,
        config: {
          ...state.config,
          entities: newConfigEntities,
          excludes: newExcludes,
          types: newConfigTypes,
          extends: newConfigExtends,
        },
        resolvedConfig: {
          ...state.resolvedConfig,
          entities: newResolvedEntities,
          types: newResolvedTypes,
        },
      };
    }

    case RESET_CONFIG:
      return defaultState;

    default:
      return state;
  }
}

// Action Creators
export const fetchConfigRequest = () => ({
  type: FETCH_CONFIG_REQUEST,
});

export const fetchConfigSuccess = payload => ({
  type: FETCH_CONFIG_SUCCESS,
  payload,
});

export const fetchConfigFail = error => ({
  type: FETCH_CONFIG_FAIL,
  error,
});

export const updateConfigRequest = payload => ({
  type: UPDATE_CONFIG_REQUEST,
  payload,
});

export const updateConfigSuccess = payload => ({
  type: UPDATE_CONFIG_SUCCESS,
  payload,
});

export const updateConfigFail = error => ({
  type: UPDATE_CONFIG_FAIL,
  error,
});

export const updateConfigLive = payload => ({
  type: UPDATE_CONFIG_LIVE,
  payload,
});

export const importConfigValid = payload => ({
  type: IMPORT_CONFIG_VALID,
  payload,
});

export const importConfigInvalid = error => ({
  type: IMPORT_CONFIG_INVALID,
  error,
});

export const clearImportConfig = () => ({
  type: CLEAR_CONFIG_TEMP,
});

export const importConfigTemp = tempConfig => ({
  type: IMPORT_CONFIG_TEMP,
  tempConfig,
});

export const resetConfig = () => ({
  type: RESET_CONFIG,
});

export const fetchImportConfigRequest = payload => ({
  type: FETCH_IMPORT_CONFIG_REQUEST,
  payload,
});

export const fetchImportConfigSuccess = payload => ({
  type: FETCH_IMPORT_CONFIG_SUCCESS,
  payload,
});

export const fetchImportConfigFail = error => ({
  type: FETCH_IMPORT_CONFIG_FAIL,
  error,
});

export const validateConfigSuccess = () => ({
  type: VALIDATE_CONFIG_SUCCESS,
});

export const validateConfigFail = error => ({
  type: VALIDATE_CONFIG_FAIL,
  error,
});

export const updateRequestStatusCode = payload => ({
  type: UPDATE_REQUEST_STATUS_CODE,
  requestStatusCode: payload,
});

export const getJWTFail = status => ({
  type: GET_JWT_FAIL,
  status,
});

export const updateConfigLiveExcludes = payload => ({
  type: UPDATE_CONFIG_LIVE_EXCLUDES,
  payload,
});

export const discardActiveTabChanges = payload => ({
  type: DISCARD_ACTIVE_TAB_CHANGES,
  payload,
});

// Sagas
export function* fetchConfigSaga() {
  try {
    const config = yield call(api.getConfigFromDb);
    const resolvedConfig = yield call(api.getResolvedConfigFromDb);

    if (get(config, 'response.status', 200) !== 200) {
      throw config.response;
    }

    if (get(resolvedConfig, 'response.status', 200) !== 200) {
      throw resolvedConfig.response;
    }

    const allConfig = {
      config,
      resolvedConfig,
    };

    yield put(fetchConfigSuccess(allConfig));
  } catch ({ data: { message }, status }) {
    if (status === 404) {
      const tenant = axios.defaults.headers.common['X-Tenant'];
      const allConfig = {
        config: { ...defaultState.config, customerConfiguration: tenant },
        resolvedConfig: { ...defaultState.resolvedConfig, customerConfiguration: tenant },
      };
      yield put(fetchConfigSuccess(allConfig));
      yield put(updateRequestStatusCode(status));
    }
    yield put(fetchConfigFail(message));
  }
}

export function* updateConfigSaga({ payload }) {
  try {
    const newPayload = {
      ...payload,
      entities: removeProperty('isExtends', payload.entities),
      types: removeProperty('isExtends', payload.types),
    };

    const updatedConfig = yield call(api.updateConfigInDb, newPayload);
    const updatedResolvedConfig = yield call(api.getResolvedConfigFromDb);

    if (get(updatedConfig, 'response.status', 200) !== 200) {
      throw updatedConfig.response;
    }

    if (get(updatedResolvedConfig, 'response.status', 200) !== 200) {
      throw updatedResolvedConfig.response;
    }

    const allConfig = {
      config: updatedConfig,
      resolvedConfig: updatedResolvedConfig,
    };

    yield put(updateConfigSuccess(allConfig));

    const { tenants: { active } } = yield select();
    yield put(showModal('Success', MODAL_TYPE_SUCCESS, `Successfully updated configuration for ${active.label}`));
  } catch ({ data: { message }, status }) {
    if (status === 404) {
      const tenant = axios.defaults.headers.common['X-Tenant'];
      const allConfig = {
        config: { ...defaultState.config, customerConfiguration: tenant },
        resolvedConfig: { ...defaultState.resolvedConfig, customerConfiguration: tenant },
      };
      yield put(fetchConfigSuccess(allConfig));
    }
    yield put(updateConfigFail(message));
    yield put(showModal('Error!', MODAL_TYPE_ERROR, message));
  }
}

export function* fetchImportConfigSaga({ payload }) {
  try {
    const { excludes, ...configurationWithoutExcludes } = payload;
    const updatedConfig = yield call(api.checkConfigValidity, configurationWithoutExcludes);

    if (get(updatedConfig, 'response.status', 200) !== 200) {
      throw Error(updatedConfig.response.data.message);
    }

    const allConfig = {
      config: configurationWithoutExcludes,
      resolvedConfig: updatedConfig,
    };

    yield put(fetchImportConfigSuccess(allConfig));
    yield put(showModal('Import config', MODAL_TYPE_SUCCESS, 'Import Config Succesful.', () => history.push('/')));
  } catch (error) {
    yield put(fetchImportConfigFail(error));
    yield put(showModal('Error!', MODAL_TYPE_ERROR, error.message));
  }
}

export const validateConfigRequest = (payload, successCallback, activeTabState, activeTab, dispatch) => ({
  type: VALIDATE_CONFIG_REQUEST,
  payload,
  successCallback,
  activeTabState,
  activeTab,
  dispatch,
});

export function* validateConfigSaga({ payload, successCallback, activeTabState, activeTab, dispatch }) {
  try {
    const newPayload = {
      ...payload,
      entities: removeProperty('isExtends', payload.entities),
      types: removeProperty('isExtends', payload.types),
    };

    const updatedConfig = yield call(api.checkConfigValidity, newPayload);

    if (get(updatedConfig, 'response.status', 200) !== 200) {
      throw Error(updatedConfig.response.data.message);
    }

    yield put(validateConfigSuccess());

    if (successCallback) {
      successCallback();
    } else {
      yield put(showModal('', MODAL_TYPE_SUCCESS, 'The current configuration is valid.'));
    }
  } catch (error) {
    yield put(validateConfigFail(error));
    if (successCallback) {
      yield put(showModal(
        'Error',
        MODAL_TYPE_ERROR,
        error.message,
        () => {},
        'Go back to tab',
        'Discard changes and continue',
        () => {
          dispatch(discardActiveTabChanges({ activeTabState, activeTab }));
          successCallback();
        },
      ));
    } else {
      yield put(showModal('Error', MODAL_TYPE_ERROR, error.message));
    }
  }
}
