// Actions
export const SHOW_MODAL = 'config-editor/modal/SHOW_MODAL';
export const HIDE_MODAL = 'config-editor/modal/HIDE_MODAL';
export const MODAL_TYPE_ERROR = 'config-editor/modal/MODAL_TYPE_ERROR';
export const MODAL_TYPE_SUCCESS = 'config-editor/modal/MODAL_TYPE_SUCCESS';
export const MODAL_TYPE_WARNING = 'config-editor/modal/MODAL_TYPE_WARNING';

// Default State
export const defaultState = {
  isModalVisible: false,
  modalType: '',
  modalTitle: '',
  modalBody: '',
  modalContinueButtonText: '',
  modalCloseButtonText: '',
  modalClassName: '',
  handleModalCloseButton: null,
  handleModalContinueButton: null,
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case SHOW_MODAL:
      const { modalTitle, modalType, modalBody, handleModalCloseButton, modalCloseButtonText, modalContinueButtonText, handleModalContinueButton, modalClassName } = action.data;

      return {
        ...state,
        isModalVisible: true,
        modalType,
        modalTitle,
        modalBody,
        handleModalCloseButton,
        modalCloseButtonText,
        modalContinueButtonText,
        handleModalContinueButton,
        modalClassName,
      };

    case HIDE_MODAL:
      return defaultState;

    default:
      return state;
  }
}

// Action Creators
export const showModal = (modalTitle, modalType, modalBody, handleModalCloseButton, modalCloseButtonText, modalContinueButtonText, handleModalContinueButton, modalClassName) => ({
  type: SHOW_MODAL,
  data: { modalTitle, modalType, modalBody, handleModalCloseButton, modalCloseButtonText, modalContinueButtonText, handleModalContinueButton, modalClassName },
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});
