import './Button.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = ({ type, children, ...props }) => {
  switch (type) {
    case 'anchor':
      return <a {...props}>{children}</a>;
    case 'link':
      return <Link {...props}>{children}</Link>;
    default:
      return <button {...props}>{children}</button>;
  }
};

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string.isRequired,
};

Button.defaultProps = {
  children: 'Import Config',
};

export default Button;
