import './Header.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import preval from 'preval.macro';

import RedBeeLogo from '../../images/redbee-logo.png';
import DeploymentImage from '../../images/deployment.png';
import Logout from '../../utils/auth/Logout';
import { MODAL_TYPE_WARNING } from '../../store/modules/modal';
import { updateTenant } from '../../api';
import { checkIsEqualConfigLocalAndServer } from '../../utils';
import Button from '../Button';
import CreateNewConfigModal from './CreateNewConfigModal';

class Header extends Component {
  onUpdateTenant = tenant => {
    const { fetchConfigRequest, setActiveTenant, showModal, configData, serverConfig } = this.props;

    if (checkIsEqualConfigLocalAndServer(configData, serverConfig)) {
      updateTenant(tenant.value);
      setActiveTenant(tenant);
      fetchConfigRequest();
    } else {
      showModal('Are you sure?', MODAL_TYPE_WARNING, 'You have unsaved changes.', () => {
      }, 'Go back to Config Editor', 'Discard changes and continue', () => {
        updateTenant(tenant.value);
        setActiveTenant(tenant);
        fetchConfigRequest();
      });
    }
  };

  showBuildVersion = () => {
    const { showModal } = this.props;
    const jenkinsBuild = window.buildId || '1.0.0';
    const jenkinsVersion = preval`module.exports = new Date().toLocaleString();`;

    const modalContent = (
      <div className="modal-with-jenkins-version">
        <img src={DeploymentImage} className="image" alt="logo" />
        <div className="item"><strong>Build:</strong> {jenkinsBuild}</div>
        <div className="item"><strong>Date:</strong> {jenkinsVersion}</div>
      </div>
    );

    showModal(
      'Deployment',
      '',
      modalContent,
    );
  };

  render() {
    const {
      tenantsData,
      activeTenant,
      configData,
      resolvedConfigData,
      showModal,
      requestStatusCode,
      updateConfigSuccess,
      updateRequestStatusCode,
      updateConfigRequest,
      validateConfigRequest,
    } = this.props;

    return (
      <header className="App__Header">
        <img src={RedBeeLogo} className="App__HeaderLogo" alt="logo" />
        <h1 className="App__HeaderLabel">Metadata Config. Editor</h1>
        <Button type="button" className="btn btn-default ButtonBar__Button" onClick={this.showBuildVersion}>
          Build Version
        </Button>
        <Logout />
        <div className="App__TenantSelection">
          <span className="App__TenantSelectionLabel">Tenant: </span>
          <Select
            className="App__TenantSelectionDropdown"
            classNamePrefix="SelectionDropdown"
            value={activeTenant}
            onChange={option => this.onUpdateTenant(option)}
            options={tenantsData}
          />
        </div>
        <CreateNewConfigModal
          tenantsData={tenantsData}
          activeTenant={activeTenant}
          configData={configData}
          resolvedConfigData={resolvedConfigData}
          showModal={showModal}
          requestStatusCode={requestStatusCode}
          updateConfigSuccess={updateConfigSuccess}
          updateRequestStatusCode={updateRequestStatusCode}
          updateConfigRequest={updateConfigRequest}
          validateConfigRequest={validateConfigRequest}
        />
      </header>
    );
  }
}

Header.propTypes = {
  fetchConfigRequest: PropTypes.func.isRequired,
  setActiveTenant: PropTypes.func.isRequired,
  tenantsData: PropTypes.array.isRequired,
  activeTenant: PropTypes.object.isRequired,
  configData: PropTypes.object.isRequired,
  resolvedConfigData: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  serverConfig: PropTypes.object.isRequired,
  requestStatusCode: PropTypes.number.isRequired,
  updateConfigSuccess: PropTypes.func.isRequired,
  updateRequestStatusCode: PropTypes.func.isRequired,
  updateConfigRequest: PropTypes.func.isRequired,
  validateConfigRequest: PropTypes.func.isRequired,
};

export default Header;
