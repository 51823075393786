import { isEqual, isNumber } from 'lodash';

export function convertConfigToEditingFormat(config) {
  const convertedConfig = JSON.parse(JSON.stringify(config));
  // EXTENDS
  delete convertedConfig.extends;
  if (config.extends) {
    convertedConfig.extends = config.extends.map(extendsItem => {
      const { permissions } = extendsItem;
      if (permissions) {
        const { permissions: { entities, relations } } = extendsItem;
        const permissionsEntities = entities && Object.entries(entities).map(([key, value]) => ({ [key]: value }));
        const permissionsRelations = relations && Object.entries(relations).map(([key, value]) => ({ [key]: value }));
        return {
          ...extendsItem,
          permissions: {
            ...permissionsEntities && { entities: permissionsEntities },
            ...permissionsRelations && { relations: permissionsRelations },
          },
        };
      }

      return extendsItem;
    });
  }

  // ENTITIES
  delete convertedConfig.entities;
  convertedConfig.entities = [];
  Object.keys(config.entities).forEach(entityName => {
    const entity = config.entities[entityName];
    convertedConfig.entities.push(convertEntityToEditingFormat(entity, entityName));
  });

  // TYPES
  if (convertedConfig.types) {
    delete convertedConfig.types;
    convertedConfig.types = [];
    Object.keys(config.types).forEach(typeName => {
      const type = config.types[typeName];
      const convertedType = JSON.parse(JSON.stringify(type));
      convertedType.name = typeName;
      convertedConfig.types.push(convertedType);
    });
  }

  return convertedConfig;
}

function convertEntityToEditingFormat(entity, entityName) {
  const convertedEntity = JSON.parse(JSON.stringify(entity));
  convertedEntity.name = entityName;

  // ATTRIBUTES
  if (entity.attributes) {
    delete convertedEntity.attributes;
    convertedEntity.attributes = [];
    Object.keys(entity.attributes).forEach(attributeName => {
      const attribute = entity.attributes[attributeName];
      const convertedAttribute = getAttributeWithName(attribute, attributeName);
      convertObjectPermissionsToEditingFormat(convertedAttribute);
      convertedEntity.attributes.push(convertedAttribute);
    });
  }

  // RELATIONS
  if (entity.relations) {
    delete convertedEntity.relations;
    convertedEntity.relations = [];
    Object.keys(entity.relations).forEach(entityType => {
      const relation = entity.relations[entityType];
      const convertedRelation = getRelationWithEntityTypeProperty(relation, entityType);
      convertObjectPermissionsToEditingFormat(convertedRelation);
      convertedEntity.relations.push(convertedRelation);
    });
  }

  // PERMISSIONS
  convertObjectPermissionsToEditingFormat(convertedEntity);

  return convertedEntity;
}

function getAttributeWithName(attribute, attributeName) {
  const convertedAttribute = JSON.parse(JSON.stringify(attribute));
  convertedAttribute.name = attributeName;
  return convertedAttribute;
}

function getRelationWithEntityTypeProperty(relation, entityType) {
  const convertedRelation = JSON.parse(JSON.stringify(relation));
  convertedRelation.entityType = entityType;
  return convertedRelation;
}

function convertObjectPermissionsToEditingFormat(o) {
  if (o.permissions) {
    const permissionsAsObject = o.permissions;
    delete o.permissions;
    const permissionsAsList = [];
    o.permissions = permissionsAsList;
    if (permissionsAsObject) {
      Object.keys(permissionsAsObject).forEach(role => {
        const value = permissionsAsObject[role];
        const convertedPermission = {
          [role]: value,
        };
        permissionsAsList.push(convertedPermission);
      });
    }
  }
}

export function convertConfigFromEditingFormat(config) {
  const convertedConfig = JSON.parse(JSON.stringify(config));

  // EXTENDS
  delete convertedConfig.extends;
  if (config?.extends) {
    convertedConfig.extends = config.extends.map(extendsItem => {
      const { permissions } = extendsItem;
      if (permissions) {
        const { permissions: { entities, relations } } = extendsItem;
        const permissionsEntities = entities && arrayToObject(entities);
        const permissionsRelations = relations && arrayToObject(relations);

        return {
          ...extendsItem,
          permissions: {
            ...permissionsEntities && { entities: permissionsEntities },
            ...permissionsRelations && { relations: permissionsRelations },
          },
        };
      }

      return extendsItem;
    });
  }

  // EXCLUDES
  delete convertedConfig.excludes;
  const newExcludes = config?.excludes?.map(({ configuration }) => ({ configuration }));
  if (newExcludes && newExcludes.length) {
    convertedConfig.excludes = newExcludes;
  }

  // ENTITIES
  delete convertedConfig.entities;
  convertedConfig.entities = {};
  if (config.entities) {
    config.entities.forEach(({ isExtends, ...rest }) => {
      convertedConfig.entities[rest.name] = conventEntityFromEditingFormat(rest);
    });
  }

  // TYPES
  delete convertedConfig.types;
  convertedConfig.types = {};
  if (config.types) {
    config.types.forEach(({ isExtends, ...rest }) => {
      const convertedType = JSON.parse(JSON.stringify(rest));
      delete convertedType.name;
      convertedConfig.types[rest.name] = convertedType;
    });
  }

  return convertedConfig;
}

function conventEntityFromEditingFormat(entity) {
  const convertedEntity = JSON.parse(JSON.stringify(entity));
  delete convertedEntity.name;

  // ATTRIBUTES
  if (entity.attributes) {
    delete convertedEntity.attributes;
    convertedEntity.attributes = {};
    entity.attributes.forEach(attribute => {
      const convertedAttribute = JSON.parse(JSON.stringify(attribute));
      delete convertedAttribute.name;
      convertObjectPermissionsFromEditingFormat(convertedAttribute);
      convertedEntity.attributes[attribute.name] = convertedAttribute;
    });
  }

  // RELATIONS
  if (entity.relations) {
    delete convertedEntity.relations;
    convertedEntity.relations = {};
    entity.relations.forEach(relation => {
      const convertedRelation = JSON.parse(JSON.stringify(relation));
      delete convertedRelation.entityType;
      convertObjectPermissionsFromEditingFormat(convertedRelation);
      convertedEntity.relations[relation.entityType] = convertedRelation;
    });
  }

  // PERMISSIONS
  convertObjectPermissionsFromEditingFormat(convertedEntity);
  return convertedEntity;
}

function convertObjectPermissionsFromEditingFormat(o) {
  if (o.permissions) {
    const permissionsAsList = o.permissions;
    delete o.permissions;
    o.permissions = {};

    if (permissionsAsList.length) {
      permissionsAsList.forEach(permission => {
        const value = permission;
        const currentValues = Object.entries(permission).flat();
        const permissionKey = currentValues[0] || 'operatorc';
        if (permission.length) {
          o.permissions[permissionKey] = value;
        } else {
          o.permissions[currentValues[0]] = currentValues[1];
        }
      });
    }
  }
}

export function getExpandedNavListState({ entities = [] }, props = []) {
  const expandedState = {
    Entities: { open: true },
    Types: { open: true },
    Namespaces: { open: true },
  };

  entities.forEach(entity => {
    const entityName = entity.displayName || entity.name;

    expandedState.Entities[entityName] = { open: true };

    Object.keys(entity).forEach(prop => {
      if (props.includes(prop)) {
        expandedState.Entities[entityName] = { ...expandedState.Entities[entityName], [prop]: {} };
      }
    });
  });

  return expandedState;
}

export const prepareKey = key => key.replace(/[^A-Za-z0-9]/g, '_').substring(0, 255);

export const serializePermissions = (data) => {
  if (Array.isArray(data)) {
    return data.map((permission, id) => {
      let permissionRole;
      let permissionValue;

      if (permission) {
        const perm = Object.entries(permission).flat();
        permissionRole = perm[0];
        permissionValue = perm[1];
      }

      return {
        id,
        role: permissionRole,
        permissions: permissionValue || '-',
      };
    });
  }

  return [];
};

export const checkIsEqualConfigLocalAndServer = (
  { entities: localConfigEntities, types: localConfigTypes, extends: localConfigExtends, excludes: localConfigExcludes },
  { entities: serverConfigEntities, types: serverConfigTypes, extends: serverConfigExtends, excludes: serverConfigExcludes },
) => isEqual(localConfigEntities, serverConfigEntities) && isEqual(localConfigTypes, serverConfigTypes) && isEqual(localConfigExtends, serverConfigExtends) && isEqual(localConfigExcludes, serverConfigExcludes);

export const gridColumnWidthSum = columns => columns.reduce((sumWidth, { width }) => sumWidth + width, 8);
export const gridColumnHeightSum = count => count * 32 + 32 + 8;

export const columnSorting = (arrayToSort, sortColumn, sortDirection) => {
  if (!arrayToSort) {
    return false;
  }

  if (sortDirection !== 'NONE' && sortColumn && arrayToSort) {
    return [...arrayToSort].sort((firstItem, secondItem) => {
      let firstItemShortNote;
      let secondItemShortNote;
      if (sortColumn === 'role') {
        firstItemShortNote = Object.keys(firstItem)[0] || '';
        secondItemShortNote = Object.keys(secondItem)[0] || '';
      } else if (sortColumn === 'rolePermissions') {
        firstItemShortNote = Object.values(firstItem)[0] || '';
        secondItemShortNote = Object.values(secondItem)[0] || '';
      } else {
        firstItemShortNote = firstItem[sortColumn] || '';
        secondItemShortNote = secondItem[sortColumn] || '';
      }


      const firstItemWithLowerCase = isNumber(firstItemShortNote) ? firstItemShortNote : JSON.stringify(firstItemShortNote).toLowerCase();
      const secondItemWithLowerCase = isNumber(secondItemShortNote) ? secondItemShortNote : JSON.stringify(secondItemShortNote).toLowerCase();

      if (sortDirection === 'ASC') {
        return firstItemWithLowerCase >= secondItemWithLowerCase ? 1 : -1;
      }
      if (sortDirection === 'DESC') {
        return firstItemWithLowerCase < secondItemWithLowerCase ? 1 : -1;
      }
      return 0;
    });
  }

  return arrayToSort;
};

export const toUpperCaseFirstLetter = (str) => {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
};

export const coloursArray = [
  '#d67373',
  'darkblue',
  'green',
  'darkorange',
  'rebeccapurple',
  'darkslategray',
  'darkred',
  'darkgoldenrod',
  '#005900',
  'darkkhaki',
  'darksalmon',
  'darkcyan',
  'deepskyblue',
  'yellowgreen',
  'dimgray',
  'chocolate',
  'mediumaquamarine',
  'tomato',
];

export const onlyUnique = (value, index, self) => value && (self.indexOf(value) === index);

export const capitalize = string => {
  if (typeof string !== 'string') return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeProperty = (propName, data) => (data ? data.map(({ [propName]: _, ...rest }) => rest) : []);

export const keysToLowerCase = obj => {
  const newObj = {};

  Object.entries(obj).forEach(([key, value]) => (newObj[key.toLowerCase()] = value));

  return newObj;
};

export const configAndResolvedWithNewFields = (config, resolvedConfig) => {
  const newResolvedConfigEntities = resolvedConfig.entities.map(entity => {
    const newAttributes = entity.attributes && entity.attributes.map((item, index) => ({
      ...item,
      displayOrder: item.displayOrder || index + 1,
      uiControl: item.uiControl || '',
    }));

    const sortedNewAttributes = newAttributes && columnSorting(newAttributes, 'displayOrder', 'ASC');
    return { ...entity, attributes: sortedNewAttributes || [], isExtends: entity.name.includes(':') };
  });

  const newResolvedConfigTypes = resolvedConfig.types && resolvedConfig.types.map(type => ({
    ...type,
    isExtends: type.name.includes(':'),
  }));
  const newConfigEntities = config.entities.map(item => {
    const resolvedConfigEntity = newResolvedConfigEntities.find(({ name: entityName }) => entityName === item.name);

    return { ...item, attributes: item.type === 'override' ? item.attributes : resolvedConfigEntity.attributes, isExtends: item.name.includes(':') };
  });

  const newConfigTypes = config.types && config.types.map(type => ({ ...type, isExtends: type.name.includes(':') }));

  const newResolvedConfig = { ...resolvedConfig, entities: newResolvedConfigEntities, types: newResolvedConfigTypes };
  const newConfig = { ...config, entities: newConfigEntities, types: newConfigTypes };

  return { newResolvedConfig, newConfig };
};

const arrayToObject = (array) => array.reduce((obj, item) => {
  const [key, value] = Object.entries(item)[0];
  return {
    ...obj,
    [key]: value,
  };
}, {});
