import React from 'react';
import { put, call } from 'redux-saga/effects';

import { getItem } from '../../utils/localStorage';

// Actions
export const FETCH_LAYOUT = 'config-editor/layout/FETCH_LAYOUT';
export const FETCH_LAYOUT_SUCCESS = 'config-editor/layout/FETCH_LAYOUT_SUCCESS';
export const FETCH_LAYOUT_FAIL = 'config-editor/layout/FETCH_LAYOUT_FAIL';

export const TOGGLE_PANEL = 'config-editor/layout/TOGGLE_PANEL';
export const RESIZE_PANEL = 'config-editor/layout/RESIZE_PANEL';

export const SAVE_NAVLIST_STATE = 'config-editor/layout/SAVE_NAVLIST_STATE';
export const SET_GRID_TAB = 'config-editor/layout/SET_GRID_TAB';

export const SET_GRAPH_POSITIONS = 'config-editor/layout/SET_GRAPH_POSITIONS';
export const SET_GRAPH_VIEWPORT = 'config-editor/layout/SET_GRAPH_VIEWPORT';
export const SET_GRID_LAYOUT = 'config-editor/layout/SET_GRID_LAYOUT';
export const SET_SELECTED_ENTITY = 'config-editor/layout/SET_SELECTED_ENTITY';
export const SET_ATTRIBUTE_REF = 'config-editor/layout/SET_ATTRIBUTE_REF';
export const SET_RELATION_GRID_REF = 'config-editor/layout/SET_RELATION_GRID_REF';

export const SET_NAMESPACES_REF = 'config-editor/layout/SET_NAMESPACES_REF';

export const SET_ACTIVE_TAB_STATE = 'config-editor/layout/SET_ACTIVE_TAB_STATE';

export const LAYOUT_COSE_BILKENT_SIZE_DEFAULT = 'Circles (default)';
export const LAYOUT_COSE_BILKENT_SIZE_BY_RELATIONS = 'Circles (sized by relations)';
export const LAYOUT_COSE_BILKENT_SIZE_BY_HIERARCHY_LEVEL = 'Circles (sized by hierarchy level)';

export const actionsForLocalStorage = [
  TOGGLE_PANEL,
  RESIZE_PANEL,
  SAVE_NAVLIST_STATE,
  SET_GRAPH_POSITIONS,
  SET_GRAPH_VIEWPORT,
  SET_GRID_TAB,
  SET_GRID_LAYOUT,
];

// Default State
export const defaultState = {
  error: null,
  panels: {
    visible: {
      navigation: true,
      grid: true,
      graph: true,
    },
    width: {
      navigation: 20,
      graph: 30,
    },
  },
  navlist: {
    Entities: {
      open: false,
    },
    Types: {
      open: false,
    },
    Namespaces: {
      open: false,
    },
  },
  graph: {
    nodes: [],
    zoom: null,
    pan: null,
  },
  gridLayout: { value: 'cose-bilkent', label: LAYOUT_COSE_BILKENT_SIZE_DEFAULT },
  gridActiveTab: '',
  selectedEntity: {},
  selectEntityIndex: null,
  attributesRef: React.createRef(),
  relationsGridRef: React.createRef(),
  namespacesRef: React.createRef(),
  activeTabState: {
    config: {},
    resoledConfig: {},
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case TOGGLE_PANEL: {
      const { panels, panels: { visible } } = state;
      const { panelName } = action;

      return {
        ...state,
        panels: {
          ...panels,
          visible: {
            ...visible,
            [panelName]: !visible[panelName],
          },
        },
      };
    }

    case RESIZE_PANEL: {
      const { panels, panels: { width } } = state;
      const { data: { panelName, width: newWidth } } = action;

      return {
        ...state,
        panels: {
          ...panels,
          width: {
            ...width,
            [panelName]: newWidth,
          },
        },
      };
    }

    case FETCH_LAYOUT: {
      return {
        ...state,
        error: null,
      };
    }

    case SET_ATTRIBUTE_REF: {
      return {
        ...state,
        attributesRef: action.attributeRef,
      };
    }

    case SET_RELATION_GRID_REF: {
      return {
        ...state,
        relationsGridRef: action.relationsGridRef,
      };
    }

    case FETCH_LAYOUT_SUCCESS: {
      const { layoutData } = action;
      return {
        ...defaultState,
        ...layoutData,
        error: null,
      };
    }

    case FETCH_LAYOUT_FAIL: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }

    case SAVE_NAVLIST_STATE: {
      const { Entities = { open: false }, Types = { open: false }, Namespaces = { open: false } } = action;

      return {
        ...state,
        navlist: { Entities, Types, Namespaces },
      };
    }

    case SET_GRAPH_POSITIONS: {
      const { graph } = state;
      const { nodes } = action;

      return {
        ...state,
        graph: {
          ...graph,
          nodes,
        },
      };
    }

    case SET_GRAPH_VIEWPORT: {
      const { graph } = state;
      const { viewportParams: { zoom, pan } } = action;

      return {
        ...state,
        graph: {
          ...graph,
          zoom,
          pan,
        },
      };
    }

    case SET_GRID_TAB: {
      const { data: { tabName } } = action;

      return {
        ...state,
        gridActiveTab: tabName,
      };
    }

    case SET_GRID_LAYOUT: {
      return {
        ...state,
        gridLayout: action.layout,
      };
    }

    case SET_SELECTED_ENTITY: {
      const { entity, id } = action.data;

      return {
        ...state,
        selectedEntity: entity,
        selectEntityIndex: id,
      };
    }

    case SET_NAMESPACES_REF: {
      return {
        ...state,
        namespacesRef: action.namespacesRef,
      };
    }

    case SET_ACTIVE_TAB_STATE: {
      const { activeTabState } = action;

      return {
        ...state,
        activeTabState,
      };
    }

    default:
      return state;
  }
}

// Action Creators
export const setAttributeRef = attributeRef => ({
  type: SET_ATTRIBUTE_REF,
  attributeRef,
});

export const setRelationsGridRef = relationsGridRef => ({
  type: SET_RELATION_GRID_REF,
  relationsGridRef,
});

export const fetchLayout = tenantName => ({
  type: FETCH_LAYOUT,
  tenantName,
});

export const fetchLayoutSuccess = layoutData => ({
  type: FETCH_LAYOUT_SUCCESS,
  layoutData,
});

export const fetchLayoutFail = error => ({
  type: FETCH_LAYOUT_FAIL,
  error,
});

export const togglePanel = panelName => ({
  type: TOGGLE_PANEL,
  panelName,
});

export const resizePanel = (panelName, width) => ({
  type: RESIZE_PANEL,
  data: { panelName, width },
});

export const saveNavlistState = ({ Entities, Types, Namespaces }) => ({
  type: SAVE_NAVLIST_STATE,
  Entities,
  Types,
  Namespaces,
});

export const setGraphPositions = nodes => ({
  type: SET_GRAPH_POSITIONS,
  nodes,
});

export const setGraphViewport = viewportParams => ({
  type: SET_GRAPH_VIEWPORT,
  viewportParams,
});

export const setGridActiveTab = (tabName) => ({
  type: SET_GRID_TAB,
  data: { tabName },
});

export const setGridLayout = layout => ({
  type: SET_GRID_LAYOUT,
  layout,
});

export const setSelectedEntity = (id, entity) => ({
  type: SET_SELECTED_ENTITY,
  data: { id, entity },
});

export const setNamespacesRef = namespacesRef => ({
  type: SET_NAMESPACES_REF,
  namespacesRef,
});

export const setActiveTabState = activeTabState => ({
  type: SET_ACTIVE_TAB_STATE,
  activeTabState,
});

// sagas
export function* fetchLayoutSaga({ tenantName }) {
  try {
    const layoutData = yield call(getItem, `layout.${tenantName}`);
    yield put(fetchLayoutSuccess(JSON.parse(layoutData)));
  } catch (error) {
    yield put(fetchLayoutFail(error));
  }
}
