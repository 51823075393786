import axios from 'axios';
import Cookies from 'js-cookie';
import { getItem } from '../utils/localStorage';

import { convertConfigToEditingFormat, convertConfigFromEditingFormat } from '../utils';
import { onLogin } from '../utils/auth/action-login';

const tokenExpiredTime = Cookies.get('authJWTExpire');
const isTokenExpired = () => Date.parse(tokenExpiredTime) < Date.now();
const setJWTToken = () => {
  const JWT = Cookies.get('authJWT');
  if (JWT) {
    axios.defaults.headers.common.Authorization = `bearer ${JWT}`;
  }
};

const readBackendUrlFromEnvFile = () => getItem('backEndpoint');

const readBackendUrlFromGlobalVariable = () => window.apiUri;

let backendUrlPrefix;

setJWTToken();

if (process.env.NODE_ENV !== 'production') {
  backendUrlPrefix = readBackendUrlFromEnvFile();
} else {
  backendUrlPrefix = readBackendUrlFromGlobalVariable();
}

const configUrl = () => `${backendUrlPrefix}/configuration`;
const configResolvedUrl = () => `${backendUrlPrefix}/configuration/resolved`;
const jwtDataUrl = () => `${backendUrlPrefix}/user`;

export const updateTenant = tenant => { axios.defaults.headers.common['X-Tenant'] = tenant; };

const axiosWithHeaders = axios.create({
  headers: { 'Cache-Control': 'no-cache' },
});

axiosWithHeaders.interceptors.request.use((config) => {
  if (isTokenExpired()) {
    onLogin();
  }

  return config;
});

export const getConfigFromDb = () => {
  const url = configUrl();

  return axiosWithHeaders.get(url)
    .then(({ data }) => convertConfigToEditingFormat(data))
    .catch(err => err);
};

export const getResolvedConfigFromDb = () => {
  const url = configResolvedUrl();

  return axiosWithHeaders.get(url)
    .then(({ data }) => convertConfigToEditingFormat(data))
    .catch(err => err);
};

export const updateConfigInDb = configInEditingFormat => {
  const config = convertConfigFromEditingFormat(configInEditingFormat);
  const url = configUrl();

  return axiosWithHeaders.post(url, config)
    .then(({ data }) => convertConfigToEditingFormat(data))
    .catch(err => err);
};

export const checkConfigValidity = configInEditingFormat => {
  const config = convertConfigFromEditingFormat(configInEditingFormat);
  const url = `${configUrl()}?commit=false`;

  return axiosWithHeaders.post(url, config)
    .then(({ data }) => convertConfigToEditingFormat(data))
    .catch(err => err);
};

export const getJWTDataFromDb = () => {
  const url = jwtDataUrl();

  return axios.get(url)
    .then(({ data }) => data)
    .catch(err => err);
};
